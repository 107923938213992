import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from "src/app/shared.service"
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-checkout-tableforten',
  templateUrl: './checkout-tableforten.component.html',
  styleUrls: ['./../../modules/user-profile/profile-overview/profile.css'],
})
export class CheckoutTablefortenComponent implements OnInit {
  email: string;
  trans: string;
  eventDate: string;
  dependent;
  membershipplan: string;
  amount;
  curDate = new Date();
  datae = new Date();
  eventid;
  contact;
  fname;
  lname;
  member_adult;
  member_kids5;
  member_kids613;
  constructor(private activatedRoute: ActivatedRoute, private ref: ChangeDetectorRef, private _location: Location, private service: SharedService) { }

  ngOnInit(): void {
    this.getrouter();
    var notes = 'Adult ' + this.member_adult + ' ticket(s), ' +
      'Kids (Under 5 years): ' + this.member_kids5 + ' ticket(s), ' +
      'Kids (6 to 13 years): ' + this.member_kids613 + ' ticket(s)';
    var data = { "email": this.email, "transactionID": this.trans, "eventid": this.eventid, "status": "payment done", "dependent": this.contact, "memberName": this.fname + " " + this.lname, "price": this.amount, "description": "payment", "notes": notes, "eventDate": this.eventDate }
    this.service.trasactiontable(data).subscribe(data => {
      this.datae = new Date();
    })
  }

  backClicked() {
    this._location.back();
  }

  getrouter() {
    this.activatedRoute.params.subscribe(params => {
      const email = params['email'];
      const eventDate = params['eventDate'];
      const eventid = params['eventid'];
      const transaction_id = params['transaction_id'];
      const amount = params['amount'];
      const contact = params['contact'];
      const fname = params['fname'];
      const lname = params['lname'];
      const member_adult = params['member_adult'];
      const member_kids5 = params['member_kids5'];
      const member_kids613 = params['member_kids613'];
      this.email = email;
      this.trans = transaction_id;
      this.eventid = eventid;
      this.amount = amount;
      this.eventDate = eventDate;
      this.contact = contact;
      this.fname = fname;
      this.lname = lname;
      this.member_adult = member_adult;
      this.member_kids5 = member_kids5;
      this.member_kids613 = member_kids613;
      // console.log("email",email);
      // console.log("membershipplan",membershipplan);
      // console.log("dependent",dependent);
      // console.log("transaction_id",transaction_id);
      // return userId;
    });
  }


}
